import axios from '../../config/Axios/axios-instance';
import {
  loginFacebookFail,
  loginFacebookStart,
  loginFacebookSuccess,
  loginFail,
  loginGoogleFail,
  loginGoogleStart,
  loginGoogleSuccess,
  loginStart,
  loginSuccess,
  logout,
  registerConfirmFail,
  registerConfirmStart,
  registerConfirmSuccess,
  registerFail,
  registerStart,
  registerSuccess,
  remindFail,
  remindStart,
  remindSuccess,
  resetPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
  validateCodeFail,
  validateCodeStart,
  validateCodeSuccess,
} from './actions';
import {Dispatch} from 'redux';

const API_AUTH_URL = '/auth';

export type LoginGoogleRequest = {
  token: string,
};

export type LoginFacebookRequest = {
  token: string,
};


export type LoginRequest = {
  email: string,
  password: string,
};

export type RegisterRequest = {
  email: string,
  password: string,
  name: string,
  surname: string,
  phone: string,
};

export type RemindRequest = {
  email: string,
};

export type ResetPasswordRequest = {
  email: string,
  password: string,
  code: string,
};

export type ValidateCodeRequest = {
  code: string,
};

export type ConfirmRegistrationRequest = {
  code: string,
};

export const loginWithGoogle =
  (inputs: LoginGoogleRequest) => (dispatch: Dispatch) => {
    dispatch(loginGoogleStart());
    return axios.post(`${API_AUTH_URL}/google`, {
      ...inputs,
    })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(loginGoogleSuccess());
      })
      .catch((err) => {
        dispatch(loginGoogleFail(err.response.data.message));
      })
    ;
  };

export const loginWithFacebook =
  (inputs: LoginFacebookRequest) => (dispatch: Dispatch) => {
    dispatch(loginFacebookStart());
    return axios.post(`${API_AUTH_URL}/facebook`, {
      ...inputs,
    })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(loginFacebookSuccess());
      })
      .catch((err) => {
        dispatch(loginFacebookFail(err.response.data.message));
      })
    ;
  };

export const login =
  (inputs: LoginRequest) => (dispatch: Dispatch) => {
    dispatch(loginStart());
    return axios.post(`${API_AUTH_URL}/login`, {
      ...inputs,
    })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(loginSuccess());
      })
      .catch((err) => {
        dispatch(loginFail(err.response?.data?.message));
      })
    ;
  };

export const register =
  (inputs: RegisterRequest) => (dispatch: Dispatch) => {
    dispatch(registerStart());
    return axios.post(`${API_AUTH_URL}/register`, {
      ...inputs,
    })
      .then(async () => {
        dispatch(registerSuccess());
      })
      .catch((err) => {
        dispatch(registerFail(err.response.data.message));
      })
    ;
  };

export const confirmRegistration =
  (inputs: ConfirmRegistrationRequest) => (dispatch: Dispatch) => {
    dispatch(registerConfirmStart());
    return axios.post(`${API_AUTH_URL}/confirm-registration`, {
      ...inputs,
    })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(registerConfirmSuccess());
      })
      .catch((err) => {
        dispatch(registerConfirmFail(err.response.data.message));
      })
    ;
  };

export const remind =
  (inputs: RemindRequest) => (dispatch: Dispatch) => {
    dispatch(remindStart());
    return axios.post(`${API_AUTH_URL}/remind`, {
      ...inputs,
    })
      .then(async (response) => {
        dispatch(remindSuccess());
      })
      .catch((err) => {
        dispatch(remindFail(err.response.data.message));
      })
    ;
  };

export const resetPassword =
  (inputs: ResetPasswordRequest) => (dispatch: Dispatch) => {
    dispatch(resetPasswordStart());
    return axios.patch(`${API_AUTH_URL}/reset-password`, {
      ...inputs,
    })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(resetPasswordSuccess());
      })
      .catch((err) => {
        dispatch(resetPasswordFail(err.response.data.message));
      })
    ;
  };

export const validateCode =
  (inputs: ValidateCodeRequest) => (dispatch: Dispatch) => {
    dispatch(validateCodeStart());
    return axios.post(`${API_AUTH_URL}/validate-confirmation-code`, {
      ...inputs,
    })
      .then(async (response) => {
        dispatch(validateCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(validateCodeFail(err.response.data.message));
      })
    ;
  };

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(loginGoogleSuccess()) : dispatch(logout());
};

export const logoutUser = () => (dispatch: Dispatch) => {
  dispatch(logout());
};
