import * as actionTypes from './actionTypes';
import {CategoryActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {CategoriesList, Category} from '../../domain/Category';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type CategoryStateType = {
  categoriesList: CategoriesList | null,
  categoriesListLoading: boolean,
  categoriesListError: HttpError,
  categoriesFilter: ListParams,
  categoriesListUpdateNeeded: boolean,
  categoryCreateLoading: boolean,
  categoryCreateError: HttpError,
  categoryCreateSuccess: boolean,
  categoryUpdateLoading: boolean,
  categoryUpdateError: HttpError,
  categoryUpdateSuccess: boolean,
  categoryDeleteLoading: boolean,
  categoryDeleteError: HttpError,
  category: Category | null,
  categoryLoading: boolean,
  categoryError: HttpError,
};

export type CategoryActionType = CategoryStateType & {
  type: CategoryActionTypes,
};

export const initialState: CategoryStateType = {
  categoriesList: null,
  categoriesListLoading: true,
  categoriesListError: null,
  categoriesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  categoriesListUpdateNeeded: false,
  categoryCreateLoading: false,
  categoryCreateError: null,
  categoryCreateSuccess: false,
  categoryUpdateLoading: false,
  categoryUpdateError: null,
  categoryUpdateSuccess: false,
  categoryDeleteLoading: false,
  categoryDeleteError: null,
  category: null,
  categoryLoading: false,
  categoryError: null,
};

const fetchCategoriesStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoriesListLoading: true,
  category: null,
  categoryUpdateSuccess: false,
  categoryCreateSuccess: false,
  categoryDeleteError: null,
});

const fetchCategoriesSuccess = (
  state: CategoryStateType, action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesList: action.categoriesList,
  categoriesListLoading: false,
  categoriesListError: null,
  categoriesListUpdateNeeded: false,
});

const fetchCategoriesFail = (
  state: CategoryStateType, action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesListError: action.categoriesListError,
  categoriesListLoading: false,
});

const fetchCategoryStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoryLoading: true,
  categoryUpdateSuccess: false,
  categoryCreateSuccess: false,
  categoryCreateError: null,
  categoryUpdateError: null,
});

const fetchCategorySuccess = (
  state: CategoryStateType, action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  category: action.category,
  categoryLoading: false,
  categoryError: null,
});

const fetchCategoryFail = (
  state: CategoryStateType, action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryError: action.categoryError,
  categoryLoading: false,
});

const createCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryCreateLoading: true,
});

const createCategorySuccess =
  (state: CategoryStateType): CategoryStateType => ({
    ...state,
    categoryCreateLoading: false,
    categoryCreateError: null,
    categoryCreateSuccess: true,
  });

const createCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryCreateLoading: false,
  categoryCreateError: action.categoryCreateError,
});

const updateCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: true,
});

const updateCategorySuccess =
  (state: CategoryStateType): CategoryStateType => ({
    ...state,
    categoryUpdateLoading: false,
    categoryUpdateError: null,
    categoryUpdateSuccess: true,
  });

const updateCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: false,
  categoryUpdateError: action.categoryUpdateError,
});

const deleteCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: true,
});

const deleteCategorySuccess =
  (state: CategoryStateType): CategoryStateType => ({
    ...state,
    categoryDeleteLoading: false,
    categoryDeleteError: null,
    categoriesListUpdateNeeded: true,
  });

const deleteCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: false,
  categoryDeleteError: action.categoryDeleteError,
});

const setCategoriesFilter = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesFilter: action.categoriesFilter,
});

const reducer = (state = initialState, action: CategoryActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_CATEGORIES_START:
    return fetchCategoriesStart(state);
  case actionTypes.FETCH_CATEGORIES_SUCCESS:
    return fetchCategoriesSuccess(state, action);
  case actionTypes.FETCH_CATEGORIES_FAIL:
    return fetchCategoriesFail(state, action);
  case actionTypes.FETCH_CATEGORY_START:
    return fetchCategoryStart(state);
  case actionTypes.FETCH_CATEGORY_SUCCESS:
    return fetchCategorySuccess(state, action);
  case actionTypes.FETCH_CATEGORY_FAIL:
    return fetchCategoryFail(state, action);
  case actionTypes.CREATE_CATEGORY_START:
    return createCategoryStart(state);
  case actionTypes.CREATE_CATEGORY_SUCCESS:
    return createCategorySuccess(state);
  case actionTypes.CREATE_CATEGORY_FAIL:
    return createCategoryFail(state, action);
  case actionTypes.UPDATE_CATEGORY_START:
    return updateCategoryStart(state);
  case actionTypes.UPDATE_CATEGORY_SUCCESS:
    return updateCategorySuccess(state);
  case actionTypes.UPDATE_CATEGORY_FAIL:
    return updateCategoryFail(state, action);
  case actionTypes.DELETE_CATEGORY_START:
    return deleteCategoryStart(state);
  case actionTypes.DELETE_CATEGORY_SUCCESS:
    return deleteCategorySuccess(state);
  case actionTypes.DELETE_CATEGORY_FAIL:
    return deleteCategoryFail(state, action);
  case actionTypes.SET_CATEGORIES_FILTER:
    return setCategoriesFilter(state, action);
  default:
    return state;
  }
};

export default reducer;
