export const FETCH_USER_RESERVATIONS_START = 'FETCH_USER_RESERVATIONS_START';
export const FETCH_USER_RESERVATIONS_SUCCESS = 'FETCH_USER_RESERVATIONS_SUCCESS';
export const FETCH_USER_RESERVATIONS_FAIL = 'FETCH_USER_RESERVATIONS_FAIL';

export const CREATE_RESERVATION_START = 'CREATE_RESERVATION_START';
export const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS';
export const CREATE_RESERVATION_FAIL = 'CREATE_RESERVATION_FAIL';

export const DELETE_RESERVATION_START = 'DELETE_RESERVATION_START';
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS';
export const DELETE_RESERVATION_FAIL = 'DELETE_RESERVATION_FAIL';

export const RESET_RESERVATION_STORE = 'RESET_RESERVATION_STORE';

export type ReservationActionTypes =
  typeof FETCH_USER_RESERVATIONS_START |
  typeof FETCH_USER_RESERVATIONS_SUCCESS |
  typeof FETCH_USER_RESERVATIONS_FAIL |
  typeof CREATE_RESERVATION_START |
  typeof CREATE_RESERVATION_SUCCESS |
  typeof CREATE_RESERVATION_FAIL |
  typeof DELETE_RESERVATION_START |
  typeof DELETE_RESERVATION_SUCCESS |
  typeof DELETE_RESERVATION_FAIL |
  typeof RESET_RESERVATION_STORE
;
