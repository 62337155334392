import * as actionTypes from './actionTypes';
import {NewsPostActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {NewsPost} from '../../domain/NewsPost';
import {ListResults} from '../../common/List/List';

export type NewsPostStateType = {
  newsPostsList: ListResults<NewsPost> | null;
  newsPostsLoading: boolean;
  newsPostsError: HttpError;
  newsPostsListUpdateNeeded: boolean;
  newsPostCreateLoading: boolean;
  newsPostCreateError: HttpError;
  newsPostCreateSuccess: boolean;
  createdNewsPost: NewsPost | null;
  newsPostUpdateLoading: boolean;
  newsPostUpdateError: HttpError;
  newsPostUpdateSuccess: boolean;
  newsPostDeleteLoading: boolean;
  newsPostDeleteError: HttpError;
  newsPostDeleteSuccess: boolean;
  newsPost: NewsPost | null;
  newsPostLoading: boolean;
  newsPostError: HttpError;
};

export type NewsPostActionType = NewsPostStateType & {
  type: NewsPostActionTypes;
};

export const initialState: NewsPostStateType = {
  newsPostsList: null,
  newsPostsLoading: true,
  newsPostsError: null,
  newsPostsListUpdateNeeded: false,
  newsPostCreateLoading: false,
  newsPostCreateError: null,
  newsPostCreateSuccess: false,
  createdNewsPost: null,
  newsPostUpdateLoading: false,
  newsPostUpdateError: null,
  newsPostUpdateSuccess: false,
  newsPostDeleteLoading: false,
  newsPostDeleteError: null,
  newsPostDeleteSuccess: false,
  newsPost: null,
  newsPostLoading: false,
  newsPostError: null,
};

const fetchNewsPostsStart = (state: NewsPostStateType): NewsPostStateType => ({
  ...state,
  newsPostsLoading: true,
});

const fetchNewsPostsSuccess = (
  state: NewsPostStateType,
  action: NewsPostActionType,
): NewsPostStateType => ({
  ...state,
  newsPostsList: action.newsPostsList,
  newsPostsLoading: false,
  newsPostsError: null,
  newsPostsListUpdateNeeded: false,
});

const fetchNewsPostsFail = (
  state: NewsPostStateType,
  action: NewsPostActionType,
): NewsPostStateType => ({
  ...state,
  newsPostsError: action.newsPostsError,
  newsPostsLoading: false,
});

const fetchNewsPostStart = (state: NewsPostStateType): NewsPostStateType => ({
  ...state,
  newsPostLoading: true,
  newsPostCreateSuccess: false,
  newsPostCreateError: null,
});

const fetchNewsPostSuccess = (
  state: NewsPostStateType,
  action: NewsPostActionType,
): NewsPostStateType => ({
  ...state,
  newsPost: action.newsPost,
  newsPostLoading: false,
  newsPostError: null,
});

const fetchNewsPostFail = (
  state: NewsPostStateType,
  action: NewsPostActionType,
): NewsPostStateType => ({
  ...state,
  newsPostError: action.newsPostError,
  newsPostLoading: false,
});

const createNewsPostStart = (state: NewsPostStateType): NewsPostStateType => ({
  ...state,
  newsPostCreateLoading: true,
});

const createNewsPostSuccess = (
  state: NewsPostStateType,
  action: NewsPostActionType,
): NewsPostStateType => ({
  ...state,
  newsPostCreateLoading: false,
  newsPostCreateError: null,
  newsPostCreateSuccess: true,
  createdNewsPost: action.createdNewsPost,
});

const createNewsPostFail = (
  state: NewsPostStateType,
  action: NewsPostActionType,
): NewsPostStateType => ({
  ...state,
  newsPostCreateLoading: false,
  newsPostCreateError: action.newsPostCreateError,
});

const updateNewsPostStart = (state: NewsPostStateType): NewsPostStateType => ({
  ...state,
  newsPostUpdateLoading: true,
  newsPostUpdateSuccess: false,
});

const updateNewsPostSuccess = (
  state: NewsPostStateType,
): NewsPostStateType => ({
  ...state,
  newsPostUpdateLoading: false,
  newsPostUpdateError: null,
  newsPostUpdateSuccess: true,
});

const updateNewsPostFail = (
  state: NewsPostStateType,
  action: NewsPostActionType,
): NewsPostStateType => ({
  ...state,
  newsPostUpdateLoading: false,
  newsPostUpdateError: action.newsPostUpdateError,
});

const deleteNewsPostStart = (state: NewsPostStateType): NewsPostStateType => ({
  ...state,
  newsPostDeleteLoading: true,
});

const deleteNewsPostSuccess = (
  state: NewsPostStateType,
): NewsPostStateType => ({
  ...state,
  newsPostDeleteLoading: false,
  newsPostDeleteError: null,
  newsPostDeleteSuccess: true,
  newsPostsListUpdateNeeded: true,
});

const deleteNewsPostFail = (
  state: NewsPostStateType,
  action: NewsPostActionType,
): NewsPostStateType => ({
  ...state,
  newsPostDeleteLoading: false,
  newsPostDeleteError: action.newsPostDeleteError,
});

const completelyResetNewsPostStore = (): NewsPostStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: NewsPostActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_NEWS_POSTS_START:
    return fetchNewsPostsStart(state);
  case actionTypes.FETCH_NEWS_POSTS_SUCCESS:
    return fetchNewsPostsSuccess(state, action);
  case actionTypes.FETCH_NEWS_POSTS_FAIL:
    return fetchNewsPostsFail(state, action);
  case actionTypes.FETCH_NEWS_POST_START:
    return fetchNewsPostStart(state);
  case actionTypes.FETCH_NEWS_POST_SUCCESS:
    return fetchNewsPostSuccess(state, action);
  case actionTypes.FETCH_NEWS_POST_FAIL:
    return fetchNewsPostFail(state, action);
  case actionTypes.CREATE_NEWS_POST_START:
    return createNewsPostStart(state);
  case actionTypes.CREATE_NEWS_POST_SUCCESS:
    return createNewsPostSuccess(state, action);
  case actionTypes.CREATE_NEWS_POST_FAIL:
    return createNewsPostFail(state, action);
  case actionTypes.UPDATE_NEWS_POST_START:
    return updateNewsPostStart(state);
  case actionTypes.UPDATE_NEWS_POST_SUCCESS:
    return updateNewsPostSuccess(state);
  case actionTypes.UPDATE_NEWS_POST_FAIL:
    return updateNewsPostFail(state, action);
  case actionTypes.DELETE_NEWS_POST_START:
    return deleteNewsPostStart(state);
  case actionTypes.DELETE_NEWS_POST_SUCCESS:
    return deleteNewsPostSuccess(state);
  case actionTypes.DELETE_NEWS_POST_FAIL:
    return deleteNewsPostFail(state, action);
  case actionTypes.RESET_NEWS_POST_STORE:
  case actionTypes.LOGOUT:
    return completelyResetNewsPostStore();
  default:
    return state;
  }
};

export default reducer;
