export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const SET_ORDERS_FILTER = 'SET_ORDERS_FILTER';

export type OrderActionTypes =
  typeof FETCH_ORDERS_START |
  typeof FETCH_ORDERS_SUCCESS |
  typeof FETCH_ORDERS_FAIL |
  typeof CREATE_ORDER_START |
  typeof CREATE_ORDER_SUCCESS |
  typeof CREATE_ORDER_FAIL |
  typeof SET_ORDERS_FILTER
;
