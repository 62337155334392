import * as actionTypes from './actionTypes';
import {OrderActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {OrdersList} from '../../domain/Order';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type OrderStateType = {
  ordersList: OrdersList | null,
  ordersLoading: boolean,
  ordersError: HttpError,
  orderCreateLoading: boolean,
  orderCreateError: HttpError,
  orderCreateSuccess: boolean,
  paymentUrl: string | null,
  ordersFilter: ListParams,
};

export type OrderActionType = OrderStateType & {
  type: OrderActionTypes,
};

export const initialState: OrderStateType = {
  ordersList: null,
  ordersLoading: true,
  ordersError: null,
  orderCreateLoading: false,
  orderCreateError: null,
  orderCreateSuccess: false,
  paymentUrl: null,
  ordersFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'orderNumber',
  },
};


const fetchOrdersStart = (
  state: OrderStateType,
): OrderStateType => ({
  ...state,
  ordersLoading: true,
});

const fetchOrdersSuccess = (
  state: OrderStateType, action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersList: action.ordersList,
  ordersLoading: false,
  ordersError: null,
});

const fetchOrdersFail = (
  state: OrderStateType, action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersError: action.ordersError,
  ordersLoading: false,
});

const createOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  orderCreateLoading: true,
});

const createOrderSuccess =
  (state: OrderStateType, action: OrderActionType): OrderStateType => ({
    ...state,
    orderCreateError: null,
    orderCreateSuccess: true,
    paymentUrl: action.paymentUrl,
  });

const createOrderFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderCreateLoading: false,
  orderCreateError: action.orderCreateError,
});

const setOrdersFilter = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersFilter: action.ordersFilter,
});

const reducer = (state = initialState, action: OrderActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_ORDERS_START:
    return fetchOrdersStart(state);
  case actionTypes.FETCH_ORDERS_SUCCESS:
    return fetchOrdersSuccess(state, action);
  case actionTypes.FETCH_ORDERS_FAIL:
    return fetchOrdersFail(state, action);
  case actionTypes.CREATE_ORDER_START:
    return createOrderStart(state);
  case actionTypes.CREATE_ORDER_SUCCESS:
    return createOrderSuccess(state, action);
  case actionTypes.CREATE_ORDER_FAIL:
    return createOrderFail(state, action);
  case actionTypes.SET_ORDERS_FILTER:
    return setOrdersFilter(state, action);
  default:
    return state;
  }
};

export default reducer;
