import {HttpError} from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';
import {AuthActionTypes} from './actionTypes';
import {User} from '../../domain/User';

export type AuthStateType = {
  isAuthenticated: boolean,
  isInitCompleted: boolean,
  loginGoogleLoading: boolean,
  loginGoogleError: HttpError,
  loginFacebookLoading: boolean,
  loginFacebookError: HttpError,
  loginLoading: boolean,
  loginError: HttpError,
  registerLoading: boolean,
  registerError: HttpError,
  registerSuccess: boolean,
  remindLoading: boolean,
  remindError: HttpError,
  isRemindSuccess: boolean,
  resetPasswordLoading: boolean,
  resetPasswordError: HttpError,
  validateCodeLoading: boolean,
  validateCodeError: HttpError,
  validatedUser: User | null,
  registerConfirmLoading: boolean,
  registerConfirmError: HttpError,
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes,
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  loginFacebookLoading: false,
  loginFacebookError: null,
  loginLoading: false,
  loginError: null,
  registerLoading: false,
  registerError: null,
  registerSuccess: false,
  remindLoading: false,
  remindError: null,
  isRemindSuccess: false,
  resetPasswordLoading: false,
  resetPasswordError: null,
  validateCodeLoading: false,
  validateCodeError: null,
  validatedUser: null,
  registerConfirmLoading: false,
  registerConfirmError: null,
};

const loginGoogleStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleLoading: true,
});

const loginGoogleSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginGoogleError: null,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const loginGoogleFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginGoogleError: action.loginGoogleError,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const loginFacebookStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginFacebookLoading: true,
});

const loginFacebookSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginFacebookError: null,
  loginFacebookLoading: false,
  isInitCompleted: true,
});

const loginFacebookFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginFacebookError: action.loginFacebookError,
  loginFacebookLoading: false,
  isInitCompleted: true,
});

const loginStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginLoading: true,
});

const loginSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginError: null,
  loginLoading: false,
  isInitCompleted: true,
});

const loginFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginError: action.loginError,
  loginLoading: false,
  isInitCompleted: true,
});

const registerStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerLoading: true,
  registerSuccess: false,
});

const registerSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerError: null,
  registerLoading: false,
  registerSuccess: true,
});

const registerFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  registerError: action.registerError,
  registerLoading: false,
});

const remindStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindLoading: true,
  validatedUser: null,
  validateCodeError: null,
});

const remindSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindError: null,
  remindLoading: false,
  isRemindSuccess: true,
});

const remindFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  remindError: action.remindError,
  remindLoading: false,
});

const resetPasswordStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  resetPasswordLoading: true,
});

const resetPasswordSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  isInitCompleted: true,
  resetPasswordError: null,
  resetPasswordLoading: false,
});

const resetPasswordFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  resetPasswordError: action.resetPasswordError,
  resetPasswordLoading: false,
  isInitCompleted: true,
});

const validateCodeStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  validateCodeLoading: true,
});

const validateCodeSuccess = (state: AuthStateType, action: AuthActionType): AuthStateType => ({
  ...state,
  validateCodeError: null,
  validateCodeLoading: false,
  validatedUser: action.validatedUser,
});

const validateCodeFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateCodeError: action.validateCodeError,
  validateCodeLoading: false,
});


const registerConfirmStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerConfirmLoading: true,
});

const registerConfirmSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  isInitCompleted: true,
  registerConfirmError: null,
  registerConfirmLoading: false,
});

const registerConfirmFail = (
  state: AuthStateType, action: AuthActionType,
): AuthStateType => ({
  ...state,
  registerConfirmError: action.registerConfirmError,
  registerConfirmLoading: false,
  isInitCompleted: true,
});

const logout = (): AuthStateType => ({
  isAuthenticated: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  isInitCompleted: true,
  loginFacebookLoading: false,
  loginFacebookError: null,
  loginLoading: false,
  loginError: null,
  registerLoading: false,
  registerError: null,
  registerSuccess: false,
  remindLoading: false,
  remindError: null,
  isRemindSuccess: false,
  resetPasswordLoading: false,
  resetPasswordError: null,
  validateCodeLoading: false,
  validateCodeError: null,
  validatedUser: null,
  registerConfirmLoading: false,
  registerConfirmError: null,
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
  case actionTypes.LOGIN_GOOGLE_START:
    return loginGoogleStart(state);
  case actionTypes.LOGIN_GOOGLE_SUCCESS:
    return loginGoogleSuccess(state);
  case actionTypes.LOGIN_GOOGLE_FAIL:
    return loginGoogleFail(state, action);
  case actionTypes.LOGIN_FACEBOOK_START:
    return loginFacebookStart(state);
  case actionTypes.LOGIN_FACEBOOK_SUCCESS:
    return loginFacebookSuccess(state);
  case actionTypes.LOGIN_FACEBOOK_FAIL:
    return loginFacebookFail(state, action);
  case actionTypes.LOGIN_START:
    return loginStart(state);
  case actionTypes.LOGIN_SUCCESS:
    return loginSuccess(state);
  case actionTypes.LOGIN_FAIL:
    return loginFail(state, action);
  case actionTypes.REGISTER_START:
    return registerStart(state);
  case actionTypes.REGISTER_SUCCESS:
    return registerSuccess(state);
  case actionTypes.REGISTER_FAIL:
    return registerFail(state, action);
  case actionTypes.REMIND_START:
    return remindStart(state);
  case actionTypes.REMIND_SUCCESS:
    return remindSuccess(state);
  case actionTypes.REMIND_FAIL:
    return remindFail(state, action);
  case actionTypes.RESET_PASSWORD_START:
    return resetPasswordStart(state);
  case actionTypes.RESET_PASSWORD_SUCCESS:
    return resetPasswordSuccess(state);
  case actionTypes.RESET_PASSWORD_FAIL:
    return resetPasswordFail(state, action);
  case actionTypes.VALIDATE_CODE_START:
    return validateCodeStart(state);
  case actionTypes.VALIDATE_CODE_SUCCESS:
    return validateCodeSuccess(state, action);
  case actionTypes.VALIDATE_CODE_FAIL:
    return validateCodeFail(state, action);
  case actionTypes.REGISTER_CONFIRM_START:
    return registerConfirmStart(state);
  case actionTypes.REGISTER_CONFIRM_SUCCESS:
    return registerConfirmSuccess(state);
  case actionTypes.REGISTER_CONFIRM_FAIL:
    return registerConfirmFail(state, action);
  case actionTypes.LOGOUT:
    return logout();
  default:
    return state;
  }
};

export default reducer;
