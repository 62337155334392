export const FETCH_NEWS_POSTS_START = 'FETCH_NEWS_POSTS_START';
export const FETCH_NEWS_POSTS_SUCCESS = 'FETCH_NEWS_POSTS_SUCCESS';
export const FETCH_NEWS_POSTS_FAIL = 'FETCH_NEWS_POSTS_FAIL';

export const FETCH_NEWS_POST_START = 'FETCH_NEWS_POST_START';
export const FETCH_NEWS_POST_SUCCESS = 'FETCH_NEWS_POST_SUCCESS';
export const FETCH_NEWS_POST_FAIL = 'FETCH_NEWS_POST_FAIL';

export const CREATE_NEWS_POST_START = 'CREATE_NEWS_POST_START';
export const CREATE_NEWS_POST_SUCCESS = 'CREATE_NEWS_POST_SUCCESS';
export const CREATE_NEWS_POST_FAIL = 'CREATE_NEWS_POST_FAIL';

export const UPDATE_NEWS_POST_START = 'UPDATE_NEWS_POST_START';
export const UPDATE_NEWS_POST_SUCCESS = 'UPDATE_NEWS_POST_SUCCESS';
export const UPDATE_NEWS_POST_FAIL = 'UPDATE_NEWS_POST_FAIL';

export const DELETE_NEWS_POST_START = 'DELETE_NEWS_POST_START';
export const DELETE_NEWS_POST_SUCCESS = 'DELETE_NEWS_POST_SUCCESS';
export const DELETE_NEWS_POST_FAIL = 'DELETE_NEWS_POST_FAIL';

export const RESET_NEWS_POST_STORE = 'RESET_NEWS_POST_STORE';

export const LOGOUT = 'LOGOUT';

export type NewsPostActionTypes =
  | typeof FETCH_NEWS_POSTS_START
  | typeof FETCH_NEWS_POSTS_SUCCESS
  | typeof FETCH_NEWS_POSTS_FAIL
  | typeof FETCH_NEWS_POST_START
  | typeof FETCH_NEWS_POST_SUCCESS
  | typeof FETCH_NEWS_POST_FAIL
  | typeof CREATE_NEWS_POST_START
  | typeof CREATE_NEWS_POST_SUCCESS
  | typeof CREATE_NEWS_POST_FAIL
  | typeof UPDATE_NEWS_POST_START
  | typeof UPDATE_NEWS_POST_SUCCESS
  | typeof UPDATE_NEWS_POST_FAIL
  | typeof DELETE_NEWS_POST_START
  | typeof DELETE_NEWS_POST_SUCCESS
  | typeof DELETE_NEWS_POST_FAIL
  | typeof RESET_NEWS_POST_STORE
  | typeof LOGOUT;
