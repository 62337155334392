export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const FETCH_ME_START = 'FETCH_ME_START';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAIL = 'FETCH_ME_FAIL';

export const FETCH_FILTERED_USERS_START = 'FETCH_FILTERED_USERS_START';
export const FETCH_FILTERED_USERS_SUCCESS = 'FETCH_FILTERED_USERS_SUCCESS';
export const FETCH_FILTERED_USERS_FAIL = 'FETCH_FILTERED_USERS_FAIL';

export const UPDATE_USER_KEY_START = 'UPDATE_USER_KEY_START';
export const UPDATE_USER_KEY_SUCCESS = 'UPDATE_USER_KEY_SUCCESS';
export const UPDATE_USER_KEY_FAIL = 'UPDATE_USER_KEY_FAIL';

export const UPDATE_USER_INFO_START = 'UPDATE_USER_INFO_START';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';

export const SET_USERS_FILTER = 'SET_USERS_FILTER';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';

export type UserActionTypes =
  typeof FETCH_USERS_START |
  typeof FETCH_USERS_SUCCESS |
  typeof FETCH_USERS_FAIL |
  typeof FETCH_USER_START |
  typeof FETCH_USER_SUCCESS |
  typeof FETCH_USER_FAIL |
  typeof FETCH_ME_START |
  typeof FETCH_ME_SUCCESS |
  typeof FETCH_ME_FAIL |
  typeof FETCH_FILTERED_USERS_START |
  typeof FETCH_FILTERED_USERS_SUCCESS |
  typeof FETCH_FILTERED_USERS_FAIL |
  typeof UPDATE_USER_KEY_START |
  typeof UPDATE_USER_KEY_SUCCESS |
  typeof UPDATE_USER_KEY_FAIL |
  typeof UPDATE_USER_INFO_START |
  typeof UPDATE_USER_INFO_SUCCESS |
  typeof UPDATE_USER_INFO_FAIL |
  typeof SET_USERS_FILTER |
  typeof ADD_TO_CART |
  typeof REMOVE_FROM_CART |
  typeof UPDATE_CART_ITEM
;
