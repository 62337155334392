import * as actionTypes from './actionTypes';
import {SlotActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Slot} from '../../domain/Slot';

export type SlotStateType = {
  slots: Slot[],
  slotsLoading: boolean,
  slotsError: HttpError,
  publicSlots: Slot[],
  publicSlotsLoading: boolean,
  publicSlotsError: HttpError,
  slotCreateLoading: boolean,
  slotCreateError: HttpError,
  slotCreateSuccess: boolean,
  createdSlot: Slot | null,
  slotUpdateLoading: boolean,
  slotUpdateError: HttpError,
  slotUpdateSuccess: boolean,
  slotDeleteLoading: boolean,
  slotDeleteError: HttpError,
  slotDeleteSuccess: boolean,
};

export type SlotActionType = SlotStateType & {
  type: SlotActionTypes,
};

export const initialState: SlotStateType = {
  slots: [],
  slotsLoading: true,
  slotsError: null,
  publicSlots: [],
  publicSlotsLoading: true,
  publicSlotsError: null,
  slotCreateLoading: false,
  slotCreateError: null,
  slotCreateSuccess: false,
  createdSlot: null,
  slotUpdateLoading: false,
  slotUpdateError: null,
  slotUpdateSuccess: false,
  slotDeleteLoading: false,
  slotDeleteError: null,
  slotDeleteSuccess: false,
};

const fetchSlotsStart = (
  state: SlotStateType,
): SlotStateType => ({
  ...state,
  slotsLoading: true,
});

const fetchSlotsSuccess = (
  state: SlotStateType, action: SlotActionType,
): SlotStateType => ({
  ...state,
  slots: action.slots,
  slotsLoading: false,
  slotsError: null,
  slotDeleteSuccess: false,
  slotUpdateSuccess: false,
  slotCreateSuccess: false,
  slotCreateError: null,
  slotUpdateError: null,
  slotDeleteError: null,
});

const fetchSlotsFail = (
  state: SlotStateType, action: SlotActionType,
): SlotStateType => ({
  ...state,
  slotsError: action.slotsError,
  slotsLoading: false,
});

const fetchPublicSlotsStart = (
  state: SlotStateType,
): SlotStateType => ({
  ...state,
  publicSlotsLoading: true,
});

const fetchPublicSlotsSuccess = (
  state: SlotStateType, action: SlotActionType,
): SlotStateType => ({
  ...state,
  publicSlots: action.publicSlots,
  publicSlotsLoading: false,
  publicSlotsError: null,
});

const fetchPublicSlotsFail = (
  state: SlotStateType, action: SlotActionType,
): SlotStateType => ({
  ...state,
  publicSlotsError: action.publicSlotsError,
  publicSlotsLoading: false,
});

const createSlotStart = (state: SlotStateType): SlotStateType => ({
  ...state,
  slotCreateLoading: true,
});

const createSlotSuccess =
  (state: SlotStateType, action: SlotActionType): SlotStateType => ({
    ...state,
    slotCreateLoading: false,
    slotCreateError: null,
    slotCreateSuccess: true,
    createdSlot: action.createdSlot,
  });

const createSlotFail = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  slotCreateLoading: false,
  slotCreateError: action.slotCreateError,
});

const updateSlotStart = (state: SlotStateType): SlotStateType => ({
  ...state,
  slotUpdateLoading: true,
});

const updateSlotSuccess =
  (state: SlotStateType): SlotStateType => ({
    ...state,
    slotUpdateLoading: false,
    slotUpdateError: null,
    slotUpdateSuccess: true,
  });

const updateSlotFail = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  slotUpdateLoading: false,
  slotUpdateError: action.slotUpdateError,
});

const deleteSlotStart = (state: SlotStateType): SlotStateType => ({
  ...state,
  slotDeleteLoading: true,
});

const deleteSlotSuccess =
  (state: SlotStateType): SlotStateType => ({
    ...state,
    slotDeleteLoading: false,
    slotDeleteError: null,
    slotDeleteSuccess: true,
  });

const deleteSlotFail = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  slotDeleteLoading: false,
  slotDeleteError: action.slotDeleteError,
});

const reducer = (state = initialState, action: SlotActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_SLOTS_START:
    return fetchSlotsStart(state);
  case actionTypes.FETCH_SLOTS_SUCCESS:
    return fetchSlotsSuccess(state, action);
  case actionTypes.FETCH_SLOTS_FAIL:
    return fetchSlotsFail(state, action);
  case actionTypes.FETCH_PUBLIC_SLOTS_START:
    return fetchPublicSlotsStart(state);
  case actionTypes.FETCH_PUBLIC_SLOTS_SUCCESS:
    return fetchPublicSlotsSuccess(state, action);
  case actionTypes.FETCH_PUBLIC_SLOTS_FAIL:
    return fetchPublicSlotsFail(state, action);
  case actionTypes.CREATE_SLOT_START:
    return createSlotStart(state);
  case actionTypes.CREATE_SLOT_SUCCESS:
    return createSlotSuccess(state, action);
  case actionTypes.CREATE_SLOT_FAIL:
    return createSlotFail(state, action);
  case actionTypes.UPDATE_SLOT_START:
    return updateSlotStart(state);
  case actionTypes.UPDATE_SLOT_SUCCESS:
    return updateSlotSuccess(state);
  case actionTypes.UPDATE_SLOT_FAIL:
    return updateSlotFail(state, action);
  case actionTypes.DELETE_SLOT_START:
    return deleteSlotStart(state);
  case actionTypes.DELETE_SLOT_SUCCESS:
    return deleteSlotSuccess(state);
  case actionTypes.DELETE_SLOT_FAIL:
    return deleteSlotFail(state, action);
  default:
    return state;
  }
};

export default reducer;
