import axios from 'axios';

export type HttpError = null | string | Array<{[key: string]: string}>;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 413) {
    error.response['data'] = {
      statusCode: 413,
      message: 'File too large (max 2MB)',
      error: 'Payload Too Large',
    };
  }

  return Promise.reject(error);
});

export default instance;
