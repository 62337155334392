import React, {ReactNode} from 'react';
import {Provider} from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, {AuthStateType} from '../../store/auth/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import languageReducer, {LanguageStateType} from '../../store/language/reducer';
import translationReducer, {TranslationStateType} from '../../store/translation/reducer';
import locationReducer, {LocationStateType} from '../../store/location/reducer';
import slotReducer, {SlotStateType} from '../../store/slot/reducer';
import reservationReducer, {ReservationStateType} from '../../store/reservation/reducer';
import userReducer, {UserStateType} from '../../store/user/reducer';
import orderReducer, {OrderStateType} from '../../store/order/reducer';
import itemReducer, {ItemStateType} from '../../store/item/reducer';
import categoryReducer, {CategoryStateType} from '../../store/category/reducer';
import newsPostReducer, {NewsPostStateType} from '../../store/news-post/reducer';

export type StoreState = {
  auth: AuthStateType,
  translation: TranslationStateType,
  language: LanguageStateType,
  location: LocationStateType,
  slot: SlotStateType,
  reservation: ReservationStateType,
  user: UserStateType,
  order: OrderStateType,
  item: ItemStateType,
  category: CategoryStateType,
  newsPost: NewsPostStateType,
};

type Props = {
  children?: ReactNode,
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  translation: translationReducer,
  language: languageReducer,
  location: locationReducer,
  slot: slotReducer,
  reservation: reservationReducer,
  user: userReducer,
  order: orderReducer,
  item: itemReducer,
  category: categoryReducer,
  newsPost: newsPostReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store: Store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk),
));

const {dispatch} = store;
instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401 &&
    error.response.config.url !== '/token') {
    dispatch(authService.logout());
  }

  return Promise.reject(error);
});

const StoreProvider = ({children}: Props) => (
  <Provider store={store}>
    {children}
  </Provider>
);

export default StoreProvider;
