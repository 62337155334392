export const routes = {
  homepage: '/',
  aboutUs: '/apie-mus',
  event: '/renginiai/:slug',
  events: '/renginiai',
  shop: '/parduotuve',
  shopItem: '/parduotuve/:id',
  cart: '/krepselis',
  checkout: '/patvirtinimas',
  contact: '/kontaktai',
  reservation: '/rezervacija/:slug?',
  privacyPolicy: '/privatumo-politika',
  refundPolicy: '/pinigu-grazinimo-salygos',
  termsAndConditions: '/pirkimo-salygos-ir-taisykles',
  newsArticle: '/naujienos/:slug',
  news: '/naujienos',
  login: '/prisijungti',
  passwordRemind: '/slaptazodzio-priminimas',
  register: '/registracija',
  passwordReset: '/slaptazodzio-atkurimas/:code',
  confirmRegistration: '/registracijos-patvirtinimas/:code',
  profile: '/profilis',
  adminReservations: '/admin',
  adminLocations: '/admin/locations',
  adminLocationCreate: '/admin/locations/new',
  adminLocationEdit: '/admin/locations/:id/edit',
  adminItems: '/admin/items',
  adminItemCreate: '/admin/items/new',
  adminItemEdit: '/admin/items/:id/edit',
  adminCategories: '/admin/categories',
  adminCategoryCreate: '/admin/categories/new',
  adminCategoryEdit: '/admin/categories/:id/edit',
  adminUsers: '/admin/users',
  adminUsersEditLock: '/admin/users/:id/edit-lock',
  adminOrders: '/admin/orders',
  adminNewsCreate: '/admin/news-posts/new',
  adminNewsEdit: '/admin/news-posts/:id/edit',
  adminNews: '/admin/news-posts',
  coaches: '/treneriai',
};
