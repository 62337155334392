export const LOGIN_GOOGLE_START = 'LOGIN_GOOGLE_START';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL';

export const LOGIN_FACEBOOK_START = 'LOGIN_FACEBOOK_START';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';
export const LOGIN_FACEBOOK_FAIL = 'LOGIN_FACEBOOK_FAIL';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const REMIND_START = 'REMIND_START';
export const REMIND_SUCCESS = 'REMIND_SUCCESS';
export const REMIND_FAIL = 'REMIND_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const VALIDATE_CODE_START = 'VALIDATE_CODE_START';
export const VALIDATE_CODE_SUCCESS = 'VALIDATE_CODE_SUCCESS';
export const VALIDATE_CODE_FAIL = 'VALIDATE_CODE_FAIL';

export const REGISTER_CONFIRM_START = 'REGISTER_CONFIRM_START';
export const REGISTER_CONFIRM_SUCCESS = 'REGISTER_CONFIRM_SUCCESS';
export const REGISTER_CONFIRM_FAIL = 'REGISTER_CONFIRM_FAIL';

export const LOGOUT = 'LOGOUT';

export type AuthActionTypes =
  typeof LOGIN_GOOGLE_START |
  typeof LOGIN_GOOGLE_SUCCESS |
  typeof LOGIN_GOOGLE_FAIL |
  typeof LOGIN_FACEBOOK_START |
  typeof LOGIN_FACEBOOK_SUCCESS |
  typeof LOGIN_FACEBOOK_FAIL |
  typeof LOGIN_START |
  typeof LOGIN_SUCCESS |
  typeof LOGIN_FAIL |
  typeof REGISTER_START |
  typeof REGISTER_SUCCESS |
  typeof REGISTER_FAIL |
  typeof REMIND_START |
  typeof REMIND_SUCCESS |
  typeof REMIND_FAIL |
  typeof RESET_PASSWORD_START |
  typeof RESET_PASSWORD_SUCCESS |
  typeof RESET_PASSWORD_FAIL |
  typeof VALIDATE_CODE_START |
  typeof VALIDATE_CODE_SUCCESS |
  typeof VALIDATE_CODE_FAIL |
  typeof REGISTER_CONFIRM_START |
  typeof REGISTER_CONFIRM_SUCCESS |
  typeof REGISTER_CONFIRM_FAIL |
  typeof LOGOUT
;
