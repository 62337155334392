import axios from '../../config/Axios/axios-instance';
import {
  fetchLanguageFail,
  fetchLanguageStart,
  fetchLanguageSuccess,
} from './actions';
import {Dispatch} from 'redux';

const API_LANGUAGE_URL = '/languages';

export const fetchLanguage =
  (locale: string) => (dispatch: Dispatch) => {
    dispatch(fetchLanguageStart());
    return axios.get(`${API_LANGUAGE_URL}/${locale}`)
      .then((response) => {
        dispatch(fetchLanguageSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchLanguageFail(err.response.data.error));
      })
    ;
  };
