import * as actionTypes from './actionTypes';
import {ReservationActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Reservation, ReservationsList} from '../../domain/Reservation';

export type ReservationStateType = {
  userReservationsList: ReservationsList | null,
  userReservationsLoading: boolean,
  userReservationsError: HttpError,
  reservationCreateLoading: boolean,
  reservationCreateError: HttpError,
  reservationCreateSuccess: boolean,
  createdReservation: Reservation | null,
  reservationDeleteLoading: boolean,
  reservationDeleteError: HttpError,
  reservationDeleteSuccess: boolean,
};

export type ReservationActionType = ReservationStateType & {
  type: ReservationActionTypes,
};

export const initialState: ReservationStateType = {
  userReservationsList: null,
  userReservationsLoading: true,
  userReservationsError: null,
  reservationCreateLoading: false,
  reservationCreateError: null,
  reservationCreateSuccess: false,
  createdReservation: null,
  reservationDeleteLoading: false,
  reservationDeleteError: null,
  reservationDeleteSuccess: false,
};

const fetchUserReservationsStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  userReservationsLoading: true,
});

const fetchUserReservationsSuccess = (
  state: ReservationStateType, action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  userReservationsList: action.userReservationsList,
  userReservationsLoading: false,
  userReservationsError: null,
});

const fetchUserReservationsFail = (
  state: ReservationStateType, action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  userReservationsError: action.userReservationsError,
  userReservationsLoading: false,
});

const createReservationStart = (state: ReservationStateType): ReservationStateType => ({
  ...state,
  reservationCreateLoading: true,
});

const createReservationSuccess =
  (state: ReservationStateType, action: ReservationActionType): ReservationStateType => ({
    ...state,
    reservationCreateLoading: false,
    reservationCreateError: null,
    reservationCreateSuccess: true,
    createdReservation: action.createdReservation,
  });

const createReservationFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  reservationCreateLoading: false,
  reservationCreateError: action.reservationCreateError,
});

const deleteReservationStart = (state: ReservationStateType): ReservationStateType => ({
  ...state,
  reservationDeleteLoading: true,
});

const deleteReservationSuccess =
  (state: ReservationStateType): ReservationStateType => ({
    ...state,
    reservationDeleteLoading: false,
    reservationDeleteError: null,
    reservationDeleteSuccess: true,
  });

const deleteReservationFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  reservationDeleteLoading: false,
  reservationDeleteError: action.reservationDeleteError,
});

const resetReservationStore = (): ReservationStateType => ({
  userReservationsList: null,
  userReservationsLoading: true,
  userReservationsError: null,
  reservationCreateLoading: false,
  reservationCreateError: null,
  reservationCreateSuccess: false,
  createdReservation: null,
  reservationDeleteLoading: false,
  reservationDeleteError: null,
  reservationDeleteSuccess: false,
});

const reducer = (state = initialState, action: ReservationActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_USER_RESERVATIONS_START:
    return fetchUserReservationsStart(state);
  case actionTypes.FETCH_USER_RESERVATIONS_SUCCESS:
    return fetchUserReservationsSuccess(state, action);
  case actionTypes.FETCH_USER_RESERVATIONS_FAIL:
    return fetchUserReservationsFail(state, action);
  case actionTypes.CREATE_RESERVATION_START:
    return createReservationStart(state);
  case actionTypes.CREATE_RESERVATION_SUCCESS:
    return createReservationSuccess(state, action);
  case actionTypes.CREATE_RESERVATION_FAIL:
    return createReservationFail(state, action);
  case actionTypes.DELETE_RESERVATION_START:
    return deleteReservationStart(state);
  case actionTypes.DELETE_RESERVATION_SUCCESS:
    return deleteReservationSuccess(state);
  case actionTypes.DELETE_RESERVATION_FAIL:
    return deleteReservationFail(state, action);
  case actionTypes.RESET_RESERVATION_STORE:
    return resetReservationStore();
  default:
    return state;
  }
};

export default reducer;
