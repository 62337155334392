import * as actionTypes from './actionTypes';
import {UserActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {User, UsersList} from '../../domain/User';
import {CartItem} from '../../domain/Cart';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type UserStateType = {
  users: User[],
  usersLoading: boolean,
  usersError: HttpError,
  user: User | null,
  userLoading: boolean,
  userError: HttpError,
  currentUser: User | null,
  currentUserLoading: boolean,
  usersList: UsersList | null,
  usersListLoading: boolean,
  usersListError: HttpError,
  usersFilter: ListParams,
  userKeyUpdateLoading: boolean,
  userKeyUpdateError: HttpError,
  userKeyUpdateSuccess: boolean,
  userInfoUpdateLoading: boolean,
  userInfoUpdateError: HttpError,
  userInfoUpdateSuccess: boolean,
  cartItems: CartItem[],
};

export type UserActionType = UserStateType & {
  type: UserActionTypes,
  cartItem: CartItem,
};

export const initialState: UserStateType = {
  users: [],
  usersLoading: true,
  usersError: null,
  user: null,
  userLoading: true,
  userError: null,
  currentUser: null,
  currentUserLoading: true,
  usersList: null,
  usersListLoading: true,
  usersListError: null,
  usersFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'user.email',
  },
  userKeyUpdateLoading: false,
  userKeyUpdateError: null,
  userKeyUpdateSuccess: false,
  userInfoUpdateLoading: false,
  userInfoUpdateError: null,
  userInfoUpdateSuccess: false,
  cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems') ?? '') : [],
};

const fetchUsersStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  usersLoading: true,
});

const fetchUsersSuccess = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  users: action.users,
  usersLoading: false,
  usersError: null,
  userKeyUpdateSuccess: false,
});

const fetchUsersFail = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  usersError: action.usersError,
  usersLoading: false,
});

const fetchUserStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userLoading: true,
});

const fetchUserSuccess = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  user: action.user,
  userLoading: false,
  userError: null,
  userKeyUpdateSuccess: false,
});

const fetchUserFail = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  userError: action.userError,
  userLoading: false,
});


const fetchCurrentUserStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  currentUserLoading: true,
});

const fetchCurrentUserSuccess = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  currentUser: action.currentUser,
  currentUserLoading: false,
});

const fetchCurrentUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const fetchFilteredUsersStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  usersListLoading: true,
});

const fetchFilteredUsersSuccess = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  usersList: action.usersList,
  usersListLoading: false,
  usersListError: null,
  userKeyUpdateSuccess: false,
});

const fetchFilteredUsersFail = (
  state: UserStateType, action: UserActionType,
): UserStateType => ({
  ...state,
  usersListError: action.usersListError,
  usersListLoading: false,
});

const updateUserKeyStart = (state: UserStateType): UserStateType => ({
  ...state,
  userKeyUpdateLoading: true,
});

const updateUserKeySuccess =
  (state: UserStateType): UserStateType => ({
    ...state,
    userKeyUpdateLoading: false,
    userKeyUpdateError: null,
    userKeyUpdateSuccess: true,
  });

const updateUserKeyFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userKeyUpdateLoading: false,
  userKeyUpdateError: action.userKeyUpdateError,
});

const updateUserInfoStart = (state: UserStateType): UserStateType => ({
  ...state,
  userInfoUpdateLoading: true,
});

const updateUserInfoSuccess =
  (state: UserStateType, action: UserActionType): UserStateType => ({
    ...state,
    userInfoUpdateLoading: false,
    userInfoUpdateError: null,
    userInfoUpdateSuccess: true,
    currentUser: action.currentUser,
  });

const updateUserInfoFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userInfoUpdateLoading: false,
  userInfoUpdateError: action.userInfoUpdateError,
});

const addToCart = (
  state: UserStateType, action: UserActionType,
): UserStateType => {
  const cartItems = [...state.cartItems, ...action.cartItems];

  localStorage.setItem('cartItems', JSON.stringify(cartItems));

  return {
    ...state,
    cartItems,
  };
};

const removeFromCart = (
  state: UserStateType, action: UserActionType,
): UserStateType => {
  const cartItems = state.cartItems.filter(
    (prevCartItem) => !action.cartItems.map((cartItem) => cartItem.uuid).includes(prevCartItem.uuid),
  );

  localStorage.setItem('cartItems', JSON.stringify(cartItems));

  return {
    ...state,
    cartItems,
  };
};

const updateCartItem = (
  state: UserStateType, action: UserActionType,
): UserStateType => {
  const cartItems = [...state.cartItems.map(
    (cartItem) => cartItem.uuid === action.cartItem.uuid ?
      ({...action.cartItem}) :
      ({...cartItem}),
  )];

  localStorage.setItem('cartItems', JSON.stringify(cartItems));

  return {
    ...state,
    cartItems,
  };
};

const setUsersFilter = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersFilter: action.usersFilter,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_USERS_START:
    return fetchUsersStart(state);
  case actionTypes.FETCH_USERS_SUCCESS:
    return fetchUsersSuccess(state, action);
  case actionTypes.FETCH_USERS_FAIL:
    return fetchUsersFail(state, action);
  case actionTypes.FETCH_USER_START:
    return fetchUserStart(state);
  case actionTypes.FETCH_USER_SUCCESS:
    return fetchUserSuccess(state, action);
  case actionTypes.FETCH_USER_FAIL:
    return fetchUserFail(state, action);
  case actionTypes.FETCH_ME_START:
    return fetchCurrentUserStart(state);
  case actionTypes.FETCH_ME_SUCCESS:
    return fetchCurrentUserSuccess(state, action);
  case actionTypes.FETCH_ME_FAIL:
    return fetchCurrentUserFail(state);
  case actionTypes.FETCH_FILTERED_USERS_START:
    return fetchFilteredUsersStart(state);
  case actionTypes.FETCH_FILTERED_USERS_SUCCESS:
    return fetchFilteredUsersSuccess(state, action);
  case actionTypes.FETCH_FILTERED_USERS_FAIL:
    return fetchFilteredUsersFail(state, action);
  case actionTypes.UPDATE_USER_KEY_START:
    return updateUserKeyStart(state);
  case actionTypes.UPDATE_USER_KEY_SUCCESS:
    return updateUserKeySuccess(state);
  case actionTypes.UPDATE_USER_KEY_FAIL:
    return updateUserKeyFail(state, action);
  case actionTypes.UPDATE_USER_INFO_START:
    return updateUserInfoStart(state);
  case actionTypes.UPDATE_USER_INFO_SUCCESS:
    return updateUserInfoSuccess(state, action);
  case actionTypes.UPDATE_USER_INFO_FAIL:
    return updateUserInfoFail(state, action);
  case actionTypes.ADD_TO_CART:
    return addToCart(state, action);
  case actionTypes.REMOVE_FROM_CART:
    return removeFromCart(state, action);
  case actionTypes.UPDATE_CART_ITEM:
    return updateCartItem(state, action);
  case actionTypes.SET_USERS_FILTER:
    return setUsersFilter(state, action);
  default:
    return state;
  }
};

export default reducer;
