import axios from '../../config/Axios/axios-instance';
import {
  fetchCurrentUserFail,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchFilteredUsersFail,
  fetchFilteredUsersStart,
  fetchFilteredUsersSuccess,
  fetchUserFail,
  fetchUsersFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUserStart,
  fetchUserSuccess,
  setUsersFilterValues,
  updateUserInfoFail,
  updateUserInfoStart,
  updateUserInfoSuccess,
  updateUserKeyFail,
  updateUserKeyStart,
  updateUserKeySuccess,
} from './actions';
import {Dispatch} from 'redux';
import {showToast} from '../../utility/toast/toast';
import {ListParams} from '../../hooks/useList/useList';

const API_URL = '/users';

export type UserKeyUpdateRequest = {
  id: number,
  lock: string,
};

export type UserInfoUpdateRequest = {
  name: string,
  surname: string,
  phone: string,
};

export const fetchUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchUsersStart());

  return axios.get(API_URL)
    .then((response) => {
      dispatch(fetchUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUsersFail(err.response.data.error));
      showToast(err.response.data.message, 'error');
    })
  ;
};

export const fetchUser = (userId: number) => (dispatch: Dispatch) => {
  dispatch(fetchUserStart());

  return axios.get(`${API_URL}/${userId}`)
    .then((response) => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserFail(err.response.data.error));
      showToast(err.response.data.message, 'error');
    })
  ;
};

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
  dispatch(fetchCurrentUserStart());

  return axios.get(`${API_URL}/me`)
    .then((response) => {
      dispatch(fetchCurrentUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCurrentUserFail());
    })
  ;
};

export const fetchFilteredUsers = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchFilteredUsersStart());
  dispatch(setUsersFilterValues(params));

  return axios.get(`${API_URL}/filtered`, {params})
    .then((response) => {
      dispatch(fetchFilteredUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchFilteredUsersFail(err.response.data.error));
      showToast(err.response.data.message, 'error');
    })
  ;
};

export const updateUserKey = (inputs: UserKeyUpdateRequest) => (dispatch: Dispatch) => {
  dispatch(updateUserKeyStart());
  const {id, ...updatedUserKey} = inputs;

  return axios.patch(`${API_URL}/${id}/update-lock`, {
    ...updatedUserKey,
  })
    .then(() => {
      dispatch(updateUserKeySuccess());
      showToast('User key was successfully updated!', 'success');
    })
    .catch((err) => {
      dispatch(updateUserKeyFail(err.response.data.message));
    })
  ;
};

export const updateUserInfo = (inputs: UserInfoUpdateRequest) => (dispatch: Dispatch) => {
  dispatch(updateUserInfoStart());
  return axios.patch(`${API_URL}/update-info`, {
    ...inputs,
  })
    .then((response) => {
      dispatch(updateUserInfoSuccess(response.data));
      showToast('User info was successfully updated!', 'success');
    })
    .catch((err) => {
      dispatch(updateUserInfoFail(err.response.data.message));
    })
  ;
};
