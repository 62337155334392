import * as actionTypes from './actionTypes';
import {User, UsersList} from '../../domain/User';
import {HttpError} from '../../config/Axios/axios-instance';
import {CartItem} from '../../domain/Cart';
import {ListParams} from '../../hooks/useList/useList';

export const fetchUsersStart = () => ({
  type: actionTypes.FETCH_USERS_START,
});

export const fetchUsersSuccess = (users: User[]) => ({
  type: actionTypes.FETCH_USERS_SUCCESS,
  users,
});

export const fetchUsersFail = (usersError: HttpError) => ({
  type: actionTypes.FETCH_USERS_FAIL,
  usersError,
});

export const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user: User) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  user,
});

export const fetchUserFail = (userError: HttpError) => ({
  type: actionTypes.FETCH_USER_FAIL,
  userError,
});

export const fetchCurrentUserStart = () => ({
  type: actionTypes.FETCH_ME_START,
});

export const fetchCurrentUserSuccess = (currentUser: User) => ({
  type: actionTypes.FETCH_ME_SUCCESS,
  currentUser,
});

export const fetchCurrentUserFail = () => ({
  type: actionTypes.FETCH_ME_FAIL,
});

export const fetchFilteredUsersStart = () => ({
  type: actionTypes.FETCH_FILTERED_USERS_START,
});

export const fetchFilteredUsersSuccess = (usersList: UsersList[]) => ({
  type: actionTypes.FETCH_FILTERED_USERS_SUCCESS,
  usersList,
});

export const fetchFilteredUsersFail = (usersListError: HttpError) => ({
  type: actionTypes.FETCH_FILTERED_USERS_FAIL,
  usersListError,
});

export const updateUserKeyStart = () => ({
  type: actionTypes.UPDATE_USER_KEY_START,
});

export const updateUserKeySuccess = () => ({
  type: actionTypes.UPDATE_USER_KEY_SUCCESS,
});

export const updateUserKeyFail = (userKeyUpdateError: string) => ({
  type: actionTypes.UPDATE_USER_KEY_FAIL,
  userKeyUpdateError,
});

export const updateUserInfoStart = () => ({
  type: actionTypes.UPDATE_USER_INFO_START,
});

export const updateUserInfoSuccess = (currentUser: User) => ({
  type: actionTypes.UPDATE_USER_INFO_SUCCESS,
  currentUser,
});

export const updateUserInfoFail = (userInfoUpdateError: string) => ({
  type: actionTypes.UPDATE_USER_INFO_FAIL,
  userInfoUpdateError,
});

export const addToCart = (cartItems: CartItem[]) => ({
  type: actionTypes.ADD_TO_CART,
  cartItems,
});

export const removeFromCart = (cartItems: CartItem[]) => ({
  type: actionTypes.REMOVE_FROM_CART,
  cartItems,
});

export const updateCartItem = (cartItem: CartItem) => ({
  type: actionTypes.UPDATE_CART_ITEM,
  cartItem,
});

export const setUsersFilterValues = (usersFilter: ListParams) => ({
  type: actionTypes.SET_USERS_FILTER,
  usersFilter,
});
