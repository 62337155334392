import * as actionTypes from './actionTypes';
import {LocationActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Location, LocationsList} from '../../domain/Location';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type LocationStateType = {
  locationsList: LocationsList | null,
  locationsLoading: boolean,
  locationsError: HttpError,
  locationsFilter: ListParams,
  locationsListUpdateNeeded: boolean,
  locationCreateLoading: boolean,
  locationCreateError: HttpError,
  locationCreateSuccess: boolean,
  createdLocation: Location | null,
  locationUpdateLoading: boolean,
  locationUpdateError: HttpError,
  locationUpdateSuccess: boolean,
  locationDeleteLoading: boolean,
  locationDeleteError: HttpError,
  location: Location | null,
  locationLoading: boolean,
  locationError: HttpError,
};

export type LocationActionType = LocationStateType & {
  type: LocationActionTypes,
};

export const initialState: LocationStateType = {
  locationsList: null,
  locationsLoading: true,
  locationsError: null,
  locationsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  locationsListUpdateNeeded: false,
  locationCreateLoading: false,
  locationCreateError: null,
  locationCreateSuccess: false,
  createdLocation: null,
  locationUpdateLoading: false,
  locationUpdateError: null,
  locationUpdateSuccess: false,
  locationDeleteLoading: false,
  locationDeleteError: null,
  location: null,
  locationLoading: false,
  locationError: null,
};

const fetchLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationsLoading: true,
});

const fetchLocationsSuccess = (
  state: LocationStateType, action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsList: action.locationsList,
  locationsLoading: false,
  locationsError: null,
  locationsListUpdateNeeded: false,
  locationUpdateSuccess: false,
  locationCreateSuccess: false,
});

const fetchLocationsFail = (
  state: LocationStateType, action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsError: action.locationsError,
  locationsLoading: false,
});

const fetchLocationStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationLoading: true,
  locationUpdateSuccess: false,
  locationCreateSuccess: false,
  locationCreateError: null,
  locationUpdateError: null,
});

const fetchLocationSuccess = (
  state: LocationStateType, action: LocationActionType,
): LocationStateType => ({
  ...state,
  location: action.location,
  locationLoading: false,
  locationError: null,
});

const fetchLocationFail = (
  state: LocationStateType, action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationError: action.locationError,
  locationLoading: false,
});

const createLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationCreateLoading: true,
});

const createLocationSuccess =
  (state: LocationStateType, action: LocationActionType): LocationStateType => ({
    ...state,
    locationCreateLoading: false,
    locationCreateError: null,
    locationCreateSuccess: true,
    createdLocation: action.createdLocation,
  });

const createLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationCreateLoading: false,
  locationCreateError: action.locationCreateError,
});

const updateLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationUpdateLoading: true,
});

const updateLocationSuccess =
  (state: LocationStateType): LocationStateType => ({
    ...state,
    locationUpdateLoading: false,
    locationUpdateError: null,
    locationUpdateSuccess: true,
  });

const updateLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationUpdateLoading: false,
  locationUpdateError: action.locationUpdateError,
});

const deleteLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationDeleteLoading: true,
});

const deleteLocationSuccess =
  (state: LocationStateType): LocationStateType => ({
    ...state,
    locationDeleteLoading: false,
    locationDeleteError: null,
    locationsListUpdateNeeded: true,
  });

const deleteLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationDeleteLoading: false,
  locationDeleteError: action.locationDeleteError,
});

const setLocationsFilter = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsFilter: action.locationsFilter,
});

const reducer = (state = initialState, action: LocationActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_LOCATIONS_START:
    return fetchLocationsStart(state);
  case actionTypes.FETCH_LOCATIONS_SUCCESS:
    return fetchLocationsSuccess(state, action);
  case actionTypes.FETCH_LOCATIONS_FAIL:
    return fetchLocationsFail(state, action);
  case actionTypes.FETCH_LOCATION_START:
    return fetchLocationStart(state);
  case actionTypes.FETCH_LOCATION_SUCCESS:
    return fetchLocationSuccess(state, action);
  case actionTypes.FETCH_LOCATION_FAIL:
    return fetchLocationFail(state, action);
  case actionTypes.CREATE_LOCATION_START:
    return createLocationStart(state);
  case actionTypes.CREATE_LOCATION_SUCCESS:
    return createLocationSuccess(state, action);
  case actionTypes.CREATE_LOCATION_FAIL:
    return createLocationFail(state, action);
  case actionTypes.UPDATE_LOCATION_START:
    return updateLocationStart(state);
  case actionTypes.UPDATE_LOCATION_SUCCESS:
    return updateLocationSuccess(state);
  case actionTypes.UPDATE_LOCATION_FAIL:
    return updateLocationFail(state, action);
  case actionTypes.DELETE_LOCATION_START:
    return deleteLocationStart(state);
  case actionTypes.DELETE_LOCATION_SUCCESS:
    return deleteLocationSuccess(state);
  case actionTypes.DELETE_LOCATION_FAIL:
    return deleteLocationFail(state, action);
  case actionTypes.SET_LOCATIONS_FILTER:
    return setLocationsFilter(state, action);
  default:
    return state;
  }
};

export default reducer;
