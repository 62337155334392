import * as actionTypes from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {User} from '../../domain/User';

export const loginGoogleStart = () => ({
  type: actionTypes.LOGIN_GOOGLE_START,
});

export const loginGoogleSuccess = () => ({
  type: actionTypes.LOGIN_GOOGLE_SUCCESS,
});

export const loginGoogleFail = (loginGoogleError: string) => ({
  type: actionTypes.LOGIN_GOOGLE_FAIL,
  loginGoogleError,
});

export const loginFacebookStart = () => ({
  type: actionTypes.LOGIN_FACEBOOK_START,
});

export const loginFacebookSuccess = () => ({
  type: actionTypes.LOGIN_FACEBOOK_SUCCESS,
});

export const loginFacebookFail = (loginFacebookError: string) => ({
  type: actionTypes.LOGIN_FACEBOOK_FAIL,
  loginFacebookError,
});


export const loginStart = () => ({
  type: actionTypes.LOGIN_START,
});

export const loginSuccess = () => ({
  type: actionTypes.LOGIN_SUCCESS,
});

export const loginFail = (loginError: HttpError) => ({
  type: actionTypes.LOGIN_FAIL,
  loginError,
});

export const registerStart = () => ({
  type: actionTypes.REGISTER_START,
});

export const registerSuccess = () => ({
  type: actionTypes.REGISTER_SUCCESS,
});

export const registerFail = (registerError: HttpError) => ({
  type: actionTypes.REGISTER_FAIL,
  registerError,
});

export const remindStart = () => ({
  type: actionTypes.REMIND_START,
});

export const remindSuccess = () => ({
  type: actionTypes.REMIND_SUCCESS,
});

export const remindFail = (remindError: HttpError) => ({
  type: actionTypes.REMIND_FAIL,
  remindError,
});

export const resetPasswordStart = () => ({
  type: actionTypes.RESET_PASSWORD_START,
});

export const resetPasswordSuccess = () => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFail = (resetPasswordError: HttpError) => ({
  type: actionTypes.RESET_PASSWORD_FAIL,
  resetPasswordError,
});

export const validateCodeStart = () => ({
  type: actionTypes.VALIDATE_CODE_START,
});

export const validateCodeSuccess = (validatedUser: User) => ({
  type: actionTypes.VALIDATE_CODE_SUCCESS,
  validatedUser,
});

export const validateCodeFail = (validateCodeError: HttpError) => ({
  type: actionTypes.VALIDATE_CODE_FAIL,
  validateCodeError,
});

export const registerConfirmStart = () => ({
  type: actionTypes.REGISTER_CONFIRM_START,
});

export const registerConfirmSuccess = () => ({
  type: actionTypes.REGISTER_CONFIRM_SUCCESS,
});

export const registerConfirmFail = (registerConfirmError: HttpError) => ({
  type: actionTypes.REGISTER_CONFIRM_FAIL,
  registerConfirmError,
});

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  return {
    type: actionTypes.LOGOUT,
  };
};
