import * as actionTypes from './actionTypes';
import {ItemActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Item, ItemsList} from '../../domain/Item';
import {DEFAULT_LIST_PARAMS, ListParams} from '../../hooks/useList/useList';

export type ItemStateType = {
  itemsList: ItemsList | null,
  itemsListLoading: boolean,
  itemsListError: HttpError,
  itemsFilter: ListParams,
  itemsListUpdateNeeded: boolean,
  itemCreateLoading: boolean,
  itemCreateError: HttpError,
  itemCreateSuccess: boolean,
  createdItem: Item | null,
  itemUpdateLoading: boolean,
  itemUpdateError: HttpError,
  itemUpdateSuccess: boolean,
  itemDeleteLoading: boolean,
  itemDeleteError: HttpError,
  item: Item | null,
  itemLoading: boolean,
  itemError: HttpError,
};

export type ItemActionType = ItemStateType & {
  type: ItemActionTypes,
};

export const initialState: ItemStateType = {
  itemsList: null,
  itemsListLoading: true,
  itemsListError: null,
  itemsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  itemsListUpdateNeeded: false,
  itemCreateLoading: false,
  itemCreateError: null,
  itemCreateSuccess: false,
  createdItem: null,
  itemUpdateLoading: false,
  itemUpdateError: null,
  itemUpdateSuccess: false,
  itemDeleteLoading: false,
  itemDeleteError: null,
  item: null,
  itemLoading: false,
  itemError: null,
};

const fetchItemsStart = (
  state: ItemStateType,
): ItemStateType => ({
  ...state,
  itemsListLoading: true,
  item: null,
  itemCreateSuccess: false,
  itemUpdateSuccess: false,
  itemDeleteError: null,
});

const fetchItemsSuccess = (
  state: ItemStateType, action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemsList: action.itemsList,
  itemsListLoading: false,
  itemsListError: null,
  itemsListUpdateNeeded: false,
});

const fetchItemsFail = (
  state: ItemStateType, action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemsListError: action.itemsListError,
  itemsListLoading: false,
});

const fetchItemStart = (
  state: ItemStateType,
): ItemStateType => ({
  ...state,
  itemLoading: true,
  itemCreateSuccess: false,
  itemUpdateSuccess: false,
  itemCreateError: null,
  itemUpdateError: null,
});

const fetchItemSuccess = (
  state: ItemStateType, action: ItemActionType,
): ItemStateType => ({
  ...state,
  item: action.item,
  itemLoading: false,
  itemError: null,
});

const fetchItemFail = (
  state: ItemStateType, action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemError: action.itemError,
  itemLoading: false,
});

const createItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemCreateLoading: true,
});

const createItemSuccess =
  (state: ItemStateType, action: ItemActionType): ItemStateType => ({
    ...state,
    itemCreateLoading: false,
    itemCreateError: null,
    itemCreateSuccess: true,
    createdItem: action.createdItem,
  });

const createItemFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemCreateLoading: false,
  itemCreateError: action.itemCreateError,
});

const updateItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemUpdateLoading: true,
});

const updateItemSuccess =
  (state: ItemStateType): ItemStateType => ({
    ...state,
    itemUpdateLoading: false,
    itemUpdateError: null,
    itemUpdateSuccess: true,
  });

const updateItemFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemUpdateLoading: false,
  itemUpdateError: action.itemUpdateError,
});

const deleteItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemDeleteLoading: true,
});

const deleteItemSuccess =
  (state: ItemStateType): ItemStateType => ({
    ...state,
    itemDeleteLoading: false,
    itemDeleteError: null,
    itemsListUpdateNeeded: true,
  });

const deleteItemFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemDeleteLoading: false,
  itemDeleteError: action.itemDeleteError,
});

const setItemsFilter = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemsFilter: action.itemsFilter,
});

const reducer = (state = initialState, action: ItemActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_ITEMS_START:
    return fetchItemsStart(state);
  case actionTypes.FETCH_ITEMS_SUCCESS:
    return fetchItemsSuccess(state, action);
  case actionTypes.FETCH_ITEMS_FAIL:
    return fetchItemsFail(state, action);
  case actionTypes.FETCH_ITEM_START:
    return fetchItemStart(state);
  case actionTypes.FETCH_ITEM_SUCCESS:
    return fetchItemSuccess(state, action);
  case actionTypes.FETCH_ITEM_FAIL:
    return fetchItemFail(state, action);
  case actionTypes.CREATE_ITEM_START:
    return createItemStart(state);
  case actionTypes.CREATE_ITEM_SUCCESS:
    return createItemSuccess(state, action);
  case actionTypes.CREATE_ITEM_FAIL:
    return createItemFail(state, action);
  case actionTypes.UPDATE_ITEM_START:
    return updateItemStart(state);
  case actionTypes.UPDATE_ITEM_SUCCESS:
    return updateItemSuccess(state);
  case actionTypes.UPDATE_ITEM_FAIL:
    return updateItemFail(state, action);
  case actionTypes.DELETE_ITEM_START:
    return deleteItemStart(state);
  case actionTypes.DELETE_ITEM_SUCCESS:
    return deleteItemSuccess(state);
  case actionTypes.DELETE_ITEM_FAIL:
    return deleteItemFail(state, action);
  case actionTypes.SET_ITEMS_FILTER:
    return setItemsFilter(state, action);
  default:
    return state;
  }
};

export default reducer;
