import React from 'react';
import StoreProvider from './config/StoreProvider/StoreProvider';
import Router from './config/Router/Router';
import {ToastContainer} from 'react-toastify';
import 'moment/locale/lt';
import moment from 'moment';
import {Helmet} from 'react-helmet';
import {GoogleOAuthProvider} from '@react-oauth/google';

moment.locale('lt');

const App = () => (
  <GoogleOAuthProvider clientId="481488485467-ec7eis33lft7ah5890sft5p92pkgca92.apps.googleusercontent.com">
    <StoreProvider>
      <Helmet>
        {process.env.REACT_APP_ENV === 'staging' && (
          <meta name="robots" content="noindex"/>
        )}
      </Helmet>
      <Router/>
      <ToastContainer/>
    </StoreProvider>
  </GoogleOAuthProvider>
);

export default App;
